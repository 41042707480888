import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  urlToBack: '/',
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setValue: (state, action) => {
      const { key, value, persist } = action.payload
      if (persist) {
        window.sessionStorage.setItem(
          key,
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        )
      }
      state[key] = value
    },
  },
})

export default commonSlice.reducer
export const {
  setValue,
} = commonSlice.actions
