import React, {
  useEffect, useState,
} from 'react'
// import Head from 'next/head'
import { SessionProvider } from 'next-auth/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { Router, useRouter } from 'next/router'
import { IntlProvider } from 'react-intl'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import esLocale from 'date-fns/locale/es'
import { wrapper } from 'redux/store'
import { PagePropsContext } from 'hooks/usePageProps'
// import dynamic from 'next/dynamic'
import { CacheProvider } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import createEmotionCache from 'createEmotionCache'
import theme from 'themes/Mexico/theme'
import styles from 'themes/Mexico/styles'
import LinearProgress from '@mui/material/LinearProgress'
import { ModalProvider } from 'components/Modal/ModalProvider'
import { Modal } from 'components/Modal'
import EventEmitter from 'services/EventEmitter'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/autoplay'
import 'globals.css'

// const DynamicAnalytics = dynamic(
//   () => import('components/Analytics'),
//   { ssr: false },
// )

const clientSideEmotionCache = createEmotionCache()

const MyApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const { messages } = pageProps
  const { locale, defaultLocale } = useRouter()
  const [queryClient] = useState(() => new QueryClient())
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const start = () => {
      setLoading(true)
    }

    const end = () => {
      setLoading(false)
    }

    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
      EventEmitter.removeAllListeners()
    }
  }, [])

  return (
    <SessionProvider>
      {/* <Head>
        {showCanonical && (
          <link
            rel="canonical"
            href={canonicalUrl}
          />
        )}
      </Head> */}
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={createTheme(theme)}>
          {loading && (
            <LinearProgress
              color="primary"
              sx={{
                position: 'fixed', top: 0, width: '100%', zIndex: 300,
              }}
            />
          )}
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <PagePropsContext.Provider value={{ ...pageProps, locale }}>
                <IntlProvider
                  locale={locale}
                  messages={messages}
                  defaultLocale={defaultLocale}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={esLocale}
                  >
                    <ModalProvider>
                      <Modal />
                      <Component />

                      {/* {process.env.NODE_ENV === 'production' && (
                        <DynamicAnalytics />
                      )} */}

                    </ModalProvider>
                  </LocalizationProvider>
                </IntlProvider>
              </PagePropsContext.Provider>
            </Hydrate>
          </QueryClientProvider>
          <CssBaseline />
          <GlobalStyles styles={styles} />
        </ThemeProvider>
      </CacheProvider>
    </SessionProvider>
  )
}

export default wrapper.withRedux(MyApp)
