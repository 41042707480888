/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  filters: {},
}

const statesSlice = createSlice({
  name: 'states',
  initialState,

  reducers: {
    setFilter: (state, action) => {
      const { key, value } = action.payload
      state.filters[key] = value
    },

    setFilters: (state, action) => {
      const filters = action.payload
      if (state?.filters) {
        Object.assign(state.filters, filters)
      } else {
        state.filters = filters
      }
    },

    resetFilters: (state) => {
      state.filters = {}
    },

    setOverridedFilters: (state, action) => {
      const filters = action.payload
      state.filters = filters
    },

    setValue: (state, action) => {
      const { key, value, persist } = action.payload
      if (persist) {
        window.sessionStorage.setItem(
          key,
          typeof value === 'object' ? JSON.stringify(value) : String(value),
        )
      }
      state[key] = value
    },
  },
})

export default statesSlice.reducer
export const {
  setFilter,
  setFilters,
  resetFilters,
  setOverridedFilters,
  setValue,
} = statesSlice.actions
