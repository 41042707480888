import { combineReducers } from 'redux'
import commonReducer from 'redux/modules/common'
import statesReducer from 'redux/modules/states'
import mapReducer from 'redux/modules/map'

export default combineReducers({
  common: commonReducer,
  states: statesReducer,
  map: mapReducer,
})
